<template>
  <div id="Overview">
    <a-row :gutter="[8, 8]">
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">区块高度</p>
          <p class="content">{{ overview.height }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">最新区块时间</p>
          <p class="content">{{ times_tamp }}秒前</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">全网有效算力</p>
          <p class="content">{{ overview.network_power }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">活跃存储提供者</p>
          <p class="content">{{ overview.active_miners }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">每区块奖励</p>
          <p class="content">{{ overview.block_reward }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">24h平均存储服务收益</p>
          <p class="content">{{ overview.avg_mining_revenue }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">近24h产出量</p>
          <p class="content">{{ overview.daily_coins_mined }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">当前扇区质押量</p>
          <p class="content">{{ overview.Sector_pledge_ratio }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">FIL质押量</p>
          <p class="content">{{ overview.total_pledge_collateral }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">24h消息数</p>
          <p class="content">{{ overview.daily_messages }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">FIL流通量</p>
          <p class="content">{{ overview.circulating_supply }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">总账户数</p>
          <p class="content">{{ overview.accounts }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">平均区块间隔</p>
          <p class="content">{{ overview.avg_tipset_interval }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">平均每高度区块数量</p>
          <p class="content">{{ overview.avg_tipset_blocks }}</p>
        </div>
      </a-col>
      <a-col :span="12" v-if="false">
        <div>
          <p class="top">32GiB新增算力成本</p>
          <p class="content">{{ overview.inc_power_cost_32 }}</p>
        </div>
      </a-col>
      <a-col :span="12" v-if="false">
        <div>
          <p class="top">64GiB新增算力成本</p>
          <p class="content">{{ overview.inc_power_cost_64 }}</p>
        </div>
      </a-col>
      <a-col :span="12" v-show="false">
        <div>
          <p class="top">32GiB扇区Gas消耗</p>
          <p class="content">{{ overview.gas_in_32 }}</p>
        </div>
      </a-col>
      <a-col :span="12" v-show="false">
        <div>
          <p class="top">64GiB扇区Gas消耗</p>
          <p class="content">{{ overview.gas_in_64 }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">平均Gas单价</p>
          <p class="content">{{ overview.avg_gas_premium }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">最新价格</p>
          <p class="content">{{ overview.price }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">当前基础费率</p>
          <p class="content">{{ overview.base_fee }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">FIL销毁量</p>
          <p class="content">{{ overview.burnt_supply }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">FIL总供给量</p>
          <p class="content">{{ overview.total_max_supply }}</p>
        </div>
      </a-col>
      <a-col :md="8" :xs="width < 375 ? 24 : 12">
        <div>
          <p class="top">FIL流通率</p>
          <p class="content">{{ overview.circulation_rate }}</p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  // 计算属性
  computed: {},
  // 数据监听
  watch: {
    "window.outerWidth"(newVal) {
      console.log(newVal);
    },
  },
  data: () => {
    return {
      width: window.outerWidth,
      overview: {},
      times_tamp: 0,
    };
  },
  methods: {
    async getOverview() {
      await this.$axios.get("overview").then((res) => {
        if (res.code === 0) {
          this.overview = res.data;
          // 设置 X 秒前更新记录 计数
          this.$IntervalID["Overview-2"] = setInterval(() => {
            this.times_tamp =
              parseInt(new Date().valueOf() / 1000) - this.overview.timestamp;
          }, 1 * 1000);
        } else {
          console.log(res.message);
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.getOverview();
    this.$IntervalID["Overview-1"] = setInterval(() => {
      this.getOverview();
    }, 30 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.$IntervalID["Overview-1"]);
    clearInterval(this.$IntervalID["Overview-2"]);
  },
};
</script>

<style lang="less">
#Overview {
  > .ant-row {
    > .ant-col {
      > div {
        font-size: 20px;
        line-height: 1.2em;
        padding: 0.4em 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        border-radius: 0.5em;
        background-image: linear-gradient(135deg, #626db2, #3b4276);
        // box-shadow: 0px 0px 0.18rem 0px rgba(0, 0, 0, 0.3);

        > p {
          min-height: 1.2em;

          &:first-child {
            font-size: 0.8em;
            color: #bcbcbc;
          }
        }
      }
    }
  }
}
</style>