
<template>
  <div id="Home">
    <div class="home-Activity">
      <Activity />
    </div>
    <div class="home-news">
      <News :newest="true" />
    </div>
    <div class="home-all-overview">
      <div class="top title components">全网概览</div>
      <div class="bottom content">
        <Overview />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Modal, Carousel } from "ant-design-vue";
Vue.use(Modal);
Vue.use(Carousel);

// 引入 全网概览 组件
import Overview from "./Overview";
// 引入 活动 组件
import Activity from "@/components/Activity";
// // 引入 公告 组件
import News from "@/components/News";

export default {
  components: {
    Overview,
    Activity,
    News,
  },
  beforeCreate() {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="less">
#Home {
  .home-Activity {
    margin: -1em;
    margin-bottom: 0;
  }
}
</style>