<template>
  <div id="news-list">
    <div class="newest" v-if="newest">
      <div class="newest-body">
        <div class="title">最新资讯</div>
        <div class="content">
          <div class="list">
            <div v-if="!newsList.length" class="swiper-slide">暂无最新公告</div>
            <a-carousel
              v-else
              :autoplay="true"
              :autoplaySpeed="5000"
              :dots="false"
              :style="{ height: '100%' }"
            >
              <router-link
                class="swiper-slide"
                v-for="(item, i) in newsList"
                :key="i"
                :index="i"
                :to="{ name: 'NewView', params: { id: item.id } }"
              >
                <div>
                  <p class="single overflow-ellipsis">{{ item.title }}</p>
                </div>
              </router-link>
            </a-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="intact" v-else></div>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel } from "ant-design-vue";
Vue.use(Carousel);

export default {
  // 父组件在标签中传递过来的数据
  props: {
    newest: {
      type: Boolean,
      default: false,
    },
  },
  // 引入的组件
  components: {},
  data: function () {
    return {
      newsList: [],
    };
  },
  watch: {},
  methods: {
    async getNewsList() {
      // 获取公告列表
      await this.$axios.get("notices").then((res) => {
        if (res.code === 0) this.newsList = res.data;
      });
    },
  },
  created() {
    // this.getNewsList();
  },
  mounted() {},
};
</script>

<style lang="less">
#news-list {
  > .newest {
    position: relative;
    @padding: 0.2em;
    padding: @padding 0;
    width: 100%;
    @height: calc(3em - (2 * @padding));
    height: 3em;
    line-height: @height;
    background-color: #3d447b;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }

    > .newest-body {
      display: flex;
      width: 100%;
      height: @height;
      overflow: hidden;

      > .title {
        flex: 0 0 auto;
        padding-right: 1em;
        color: #dc3f58;
        letter-spacing: 0.1em;
        font-weight: bold;
      }

      > .content {
        flex: 0 1 100%;
        position: relative;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 0.3em;

        > .list {
          width: 100%;
          height: 100%;
          // overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .ant-carousel * {
      height: @height;
      line-height: @height;
    }

    .swiper-slide {
      padding: 0 0.5em;
    }
  }
}
</style>